import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import * as Style from '../../components/css/pages/pageResursbank.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import ResursbankImg from '../../images/info/resursbank.jpg'
const Resursbank = () => (
  <Layout>
    <Seo title='Resursbank' />
    <div className={`container-fluid resursbank ${Style.resursbank}`}>
      <div className={`row align-items-center justify-content-center resursbankRow  ${Style.resursbankRow}`}>
        <div className='col-12 col-sm-12 col-md-8 col-lg-5 col-xl-4 text-center'>
          <div className={`card ${Style.card} bg-primary mb-5`}>
            <img className={`card-img-top ${Style.cardImgTop}`} src={ResursbankImg} alt='resursbank' />
            <div className='card-body'>
              <h1 className='card-title text-white'>Avbetalning med resursbank</h1>
              <p className='card-text mb-0'>Samarbete med resursbank via STR</p>
              {/* <a target='_blank' rel='noopener noreferrer' href='https://www.str.se/globalassets/mina-sidor/medlemsformaner/resurs_bank_folder.pdf' className='btn btn-warning my-3 mr-3' role='button'>Läs med om villkoren här  <FontAwesomeIcon icon={faExternalLinkAlt} /></a> */}
              <h3 className='my-0 text-white'>Ansök här</h3>
              <a href='https://apponline.resurs.com/form/SE6300N' className='btn btn-danger my-3 mr-2 pl-4' role='button'>STR-konto <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
              <a href='https://apponline.resurs.com/form/SE6300S' className='btn btn-danger' role='button'>Student-konto <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
              <br />
              <i className='mb-0'>Kontakta Resurs Bank på mail kundservice@resurs.se alt telefon 042-38 20 93 eller gör din ansökan direkt här på vår hemsida.</i>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Layout>
)

export default Resursbank
